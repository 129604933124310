import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./templates/Home";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { loadFromLocalStorage, getQuestionsFromLocalStorage, loadVideoStatusFromLocalStorage } from "./app/store";
import LoginComponent from "./components/LoginComponent";

const App = () => {

  const questions = useSelector((state)=> state.questions)
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(loadFromLocalStorage())
    dispatch(loadVideoStatusFromLocalStorage())

  });
  useEffect(()=>{
    if(!Object.keys(questions.questions).length){
      dispatch(getQuestionsFromLocalStorage())
    }
  });

  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:hash" element={<LoginComponent />} />
      </Routes>
    </BrowserRouter>
    </>
  );
};
export default App;
