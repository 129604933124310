import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { selectAnswerGlobal,setExamFinished,setExamPassed,setScore,unselectAnswerGlobal } from '../app/store'

const Quiz = () => {

  const { i18n,t } = useTranslation();
  const user = useSelector((state)=> state.user)
  const questions = useSelector((state)=> state.questions)
  const dispatch = useDispatch()
  const [curQuestion,setCurQuestion]=useState({})
  const [curAnswers,setCurAnswers]=useState([])

  const selectAnswer = (letter) => {
    setCurQuestion({
      ...curQuestion,
      selected_letter: letter
    })
  }

  const handleForward = (letter,number) => {
    if(!letter){
      alert(t('seleziona_opzione'))
    } else {
      dispatch(selectAnswerGlobal({
        letter,
        number
      }))
      let answer_correct = false
      for(let a of curAnswers){
        if(a.letter === letter && a.correct){
          dispatch(setScore(user.score+1))
          answer_correct =true
        }
      }
      if(curQuestion.number===questions.tot_questions || curQuestion.number===parseInt(questions.tot_questions)){
        dispatch(setExamFinished(true))
        if(questions.min_score_to_pass<=user.score){
          dispatch(setExamPassed(true))
        }
        /* Fix per il caso in cui l'esame non viene settato come passato se la
        risposta all'ultima domanda è quella che fa raggiungere il punteggio minimo */
        if(user.score===(questions.min_score_to_pass-1) && answer_correct ){
          dispatch(setExamPassed(true))
        }
      }
    }
  }
  const handleBackward = (number) => {
    dispatch(unselectAnswerGlobal({number}))
    dispatch(setScore(user.score-1))
  }

  useEffect(
    ()=>{
      const curLanguage=i18n.language
      const languageQuestionsSet=questions.questions[curLanguage]
      let c=0;
      for(let q of languageQuestionsSet){
        if(q.confirmed){
          c++;
        }
      }
      let answers = []
      answers.push({letter: 'A', text: languageQuestionsSet[c].answers.A.text, correct: languageQuestionsSet[c].answers.A.correct})
      answers.push({letter: 'B', text: languageQuestionsSet[c].answers.B.text, correct: languageQuestionsSet[c].answers.B.correct})
      answers.push({letter: 'C', text: languageQuestionsSet[c].answers.C.text, correct: languageQuestionsSet[c].answers.C.correct})

      setCurQuestion(languageQuestionsSet[c])
      if(languageQuestionsSet[c].number===1){
        dispatch(setScore(0))
      }
      setCurAnswers(answers)
    },[i18n.language, questions.questions,dispatch]
  )

  return (
    <>
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <h3 className='mb-3 text-center d-none-small'>{t('di_seguito_domande_verifica')}</h3>
          <h5 className='h5 text-center'><span className='material-symbols-rounded shift-down-little'>info</span> <span>{t('clicca_sulla_risposta')}</span></h5>
          <div className='row'>
            <div className='col-12 mt-4'>
            <span className='float-end'>{questions.min_score_to_pass}</span><span className='float-end me-2'>{t('punteggio_minimo')}:</span><br className='d-none-big' />
              <span className=''>{t('domanda')}:</span> <span className=''>{curQuestion.number+' / '+questions.tot_questions}</span>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 border rounded-3 pt-4 pb-1 mt-1 bg-lightgray'>
              <h6 className='h6 mb-3 ms-1'>{curQuestion.number}. {curQuestion.text}?</h6>

              {
                curAnswers.map((el,idx)=> {return <div key={idx} className={'answer row mb-3 '+(curQuestion.selected_letter===el.letter?'selected':'')} onClick={()=>{selectAnswer(el.letter)}}>
                <div className='col-3 col-md-2 d-flex align-items-center'>
                <span className='letter ms-0 ms-md-4 ms-lg-5'>{el.letter}</span>
                <input type="radio" checked={curQuestion.selected_letter===el.letter} onChange={()=>{selectAnswer(el.letter)}} name={curQuestion.number+'_radio'} value={el.letter} className='ms-2' data-correct={el.correct}/>
                </div>
                  <div className='col-9 col-md-10'>
                    <span className='answer-text ms-0 ms-md-3'>{el.text}</span>
                </div>
              </div>})
              }

            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 mt-4'>
          {curQuestion.number !==1 && <button type="button" onClick={()=>{handleBackward(curQuestion.number)}} className='basf-btn ms-0 float-start'><span className='material-symbols-rounded shift-down-little button-icon'>arrow_back_ios_new</span> {t('indietro')}</button>}
          <button type="button" onClick={()=>{handleForward(curQuestion.selected_letter,curQuestion.number)}} className='basf-btn ms-0 float-end'>{curQuestion.number < questions.tot_questions?t('avanti'):t('concludi')} <span className='material-symbols-rounded shift-down-little button-icon-right'>arrow_forward_ios</span></button>
        </div>
      </div>
    </div>
    </>
  )
}

export default Quiz