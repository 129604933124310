import React, { useCallback, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { logIn, logOut, saveInLocalStorage, setQuestions, setWrongLogin } from '../app/store'
import axios from 'axios'

const LoginComponent = () => {

    const { t } = useTranslation();
    const {hash} = useParams()
    const dispatch = useDispatch()
    const [loadingClass,setLoadingClass]=useState('invisible-over-page')
    

    const login = useCallback(async(badge) => {
        const url = process.env.REACT_APP_LOCAL_BACKEND_URL+'get-candidate';
        const config = {
          headers: {'Authorization': process.env.REACT_APP_LOCAL_BACKEND_TOKEN},
          params: {user: badge}
        }
        try {
          const response = await axios.get(url,config)
          const newuser = response.data.data.candidate
          dispatch(logIn(newuser))
          dispatch(saveInLocalStorage())
          const tot_question_number = newuser.access_type===1?process.env.REACT_APP_DRIVER_TOT_QUESTIONS:(newuser.access_type===2?process.env.REACT_APP_CONTRACTOR_TOT_QUESTIONS:process.env.REACT_APP_VISITOR_TOT_QUESTIONS)
          const pass_score = newuser.access_type===1?process.env.REACT_APP_DRIVER_PASS_SCORE:(newuser.access_type===2?process.env.REACT_APP_CONTRACTOR_PASS_SCORE:process.env.REACT_APP_VISITOR_PASS_SCORE)
          dispatch(setQuestions({
            questions: response.data.data.questions,
            tot_question_number: tot_question_number,
            pass_score: pass_score
          }))
        } catch(error){
            console.log(error)
          dispatch(logOut())
          dispatch(setWrongLogin(true))
        }
        document.location.href='/'
      },[dispatch])

      useEffect(
        ()=>{
            setLoadingClass('center-in-page')
            login(hash)
        },[login,hash]
    )

  return (
    <>
    <div className={'container '+loadingClass}>
      <div className='row'>
        <div className='col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 text-center py-5 border rounded-2 bg-gray'>
          <h4 className='py-5'>{t('login_in_corso')}...</h4>
        </div>
      </div>
    </div>
    </>
  )
}

export default LoginComponent