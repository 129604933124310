export const translations =
{
    it: {
      translation: {
        formazione: "Formazione",
        utente_loggato: 'Utente loggato',
        utente_non_loggato: 'Utente non loggato',
        accedi_con_badge: 'Accedi con il tuo numero di badge',
        accedi: 'accedi',
        badge_numero: 'Badge n°',
        badge_non_trovato: 'Numero badge errato, per favore riprova',
        aiuto_accesso: 'Se non ti è stato assegnato un badge, puoi richiedere l\'accesso tramite QR code alla reception',
        congratulazioni: 'Congratulazioni',
        esame_superato: 'Hai superato l\'esame',
        esito: 'Il tuo risultato',
        risposte_corrette: 'Risposte corrette',
        dato_non_disponibile: 'Dato non disponibile',
        esci: 'ESCI',
        fine: 'Fine',
        avviso_logout: 'Attenzione: se clicchi su logout, l\'esame sarà annullato',
        invio_risultato_fallito: 'Invio dei dati fallito, riprova o contatta il personale BASF',
        benvenuto: 'Benvenuto/a',
        istruzioni_video: 'Guarda per intero il video, quindi clicca su "Inizia il test"',
        inizia_il_test: 'Inizia il test',
        indietro_30_secondi: 'Indietro 30 secondi',
        avanti_30_secondi: 'Avanti 30 secondi',
        login_in_corso: 'Sto effettuando il login',
        risultato_inviato: 'Risultato inviato con successo',
        di_seguito_domande_verifica: 'Nello spazio sottostante ci sono alcune domande di verifica',
        clicca_sulla_risposta: 'Clicca sulla risposta corretta, quindi premi "Avanti" per confermare',
        avanti: 'Avanti',
        indietro: 'Indietro',
        domanda: 'Domanda',
        punteggio_minimo: 'Punteggio minimo per passare',
        seleziona_opzione: 'Seleziona una risposta prima di procedere',
        concludi: 'Concludi',
        peccato: 'Peccato',
        esame_non_superato: 'L\'esame è fallito',
        riguarda_video: 'Riguarda video',
        riprova_quiz: 'Riprova l\'esame',
      },
    },
    en: {
      translation: {
        formazione: "Training",
        utente_loggato: 'User logged in',
        utente_non_loggato: 'User not logged in',
        accedi_con_badge: 'Access with your badge number',
        accedi: 'enter',
        badge_numero: 'Badge Nr.',
        badge_non_trovato: 'Badge number wrong, please try again',
        aiuto_accesso: 'If you have not been assigned a badge, you can request access via QR code at the reception',
        congratulazioni: 'Congratulations',
        esame_superato: 'You passed the exam',
        esito: 'Your result',
        risposte_corrette: 'Correct answers',
        dato_non_disponibile: 'Data not available',
        esci: 'EXIT',
        fine: 'End',
        avviso_logout: 'Warning: if you click on logout, your exam result will be lost',
        invio_risultato_fallito: 'Sending data failed, please try again or contact BASF personnel',
        benvenuto: 'Welcome',
        istruzioni_video: 'Watch the entire video, then click "Start Test"',
        inizia_il_test: 'Start Test',
        indietro_30_secondi: 'Rewind 30 seconds',
        avanti_30_secondi: 'Forward 30 seconds',
        login_in_corso: 'Logging in',
        risultato_inviato: 'Result successfully sent',
        di_seguito_domande_verifica: 'In the space below there are some verification questions',
        clicca_sulla_risposta: 'Click on the correct answer, then press "Next" to confirm',
        avanti: 'Next',
        indietro: 'Previous',
        domanda: 'Question',
        punteggio_minimo: 'Minimum score to pass',
        seleziona_opzione: 'Select an answer before proceeding',
        concludi: 'End',
        peccato: 'What a pity',
        esame_non_superato: 'The exam was failed',
        riguarda_video: 'Rewatch video',
        riprova_quiz: 'Try exam again',
      },
    },
    de: {
      translation: {
        formazione: "Ausbildung",
        utente_loggato: 'Angemeldeter Benutzer',
        utente_non_loggato: 'Benutzer nicht angemeldet',
        accedi_con_badge: 'Zugang mit Ihrer Ausweisnummer',
        accedi: 'Anmelden',
        badge_numero: 'Nummer des Ausweises:',
        badge_non_trovato: 'Ausweisnummer falsch, bitte versuchen Sie es erneut',
        aiuto_accesso: 'Wenn Ihnen kein Badge zugewiesen wurde, können Sie den Zutritt per QR-Code an der Rezeption beantragen',
        congratulazioni: 'Glückwunsch',
        esame_superato: 'Sie haben die Prüfung bestanden',
        esito: 'Ihr Ergebnis',
        risposte_corrette: 'Richtige Antworten',
        dato_non_disponibile: 'Daten nicht verfügbar',
        esci: 'BEENDEN',
        fine: 'Ende',
        avviso_logout: 'Bitte beachten Sie: Wenn Sie auf Abmelden klicken, wird die Prüfung abgebrochen',
        invio_risultato_fallito: 'Das Senden von Daten ist fehlgeschlagen, bitte versuchen Sie es erneut oder wenden Sie sich an BASF-Mitarbeiter',
        benvenuto: 'Willkommen',
        istruzioni_video: 'Sehen Sie sich das gesamte Video an und klicken Sie dann auf „Test starten“',
        inizia_il_test: 'Test starten',
        indietro_30_secondi: '30 Sekunden zurückspulen',
        avanti_30_secondi: '30 Sekunden vorwärts',
        login_in_corso: 'Ich melde mich an',
        risultato_inviato: 'Ergebnis erfolgreich gesendet',
        di_seguito_domande_verifica: 'Im Feld unten finden Sie einige Verifizierungsfragen',
        clicca_sulla_risposta: 'Klicken Sie auf die richtige Antwort und bestätigen Sie mit „Weiter“',
        avanti: 'Nach Ihnen',
        indietro: 'Rückwärts',
        domanda: 'Anfrage',
        punteggio_minimo: 'Mindestpunktzahl zum Bestehen',
        seleziona_opzione: 'Bitte wählen Sie eine Antwort aus, bevor Sie fortfahren',
        concludi: 'Daraus schließen',
        peccato: 'Was für eine Schande',
        esame_non_superato: 'Die Prüfung ist fehlgeschlagen',
        riguarda_video: 'Sehen Sie sich das Video noch',
        riprova_quiz: 'Versuchen Sie die Prüfung erneut',
      },
    },
}
