import React, { useRef, useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { logIn, logOut, saveInLocalStorage, setQuestions, setWrongLogin } from '../app/store'
import axios from 'axios';

const Loginform = () => {

    const { t } = useTranslation()
    const user = useSelector((state)=> state.user)
    const dispatch = useDispatch()
    const [badge,setBadge] = useState('')
    const inputRef = useRef()
    // Imposta il focus sull'input all'avvio
    useEffect(() => {
      inputRef.current.focus();
    }, []);
    const makeLogin = (e) => {
        e.preventDefault()
        login(badge)
    }

    const login = async(badge) => {
        const url = process.env.REACT_APP_LOCAL_BACKEND_URL+'get-candidate';
        const config = {
          headers: {'Authorization': process.env.REACT_APP_LOCAL_BACKEND_TOKEN},
          params: {user: badge}
        }
        try {
          const response = await axios.get(url,config)
          const newuser = response.data.data.candidate
          dispatch(logIn(newuser))
          dispatch(saveInLocalStorage())
          const tot_question_number = newuser.access_type===1?process.env.REACT_APP_DRIVER_TOT_QUESTIONS:(newuser.access_type===2?process.env.REACT_APP_CONTRACTOR_TOT_QUESTIONS:process.env.REACT_APP_VISITOR_TOT_QUESTIONS)
          const pass_score = newuser.access_type===1?process.env.REACT_APP_DRIVER_PASS_SCORE:(newuser.access_type===2?process.env.REACT_APP_CONTRACTOR_PASS_SCORE:process.env.REACT_APP_VISITOR_PASS_SCORE)
          dispatch(setQuestions({
            questions: response.data.data.questions,
            tot_question_number: tot_question_number,
            pass_score: pass_score
        }))
        } catch(error){
            console.log(error)
          dispatch(logOut())
          dispatch(setWrongLogin(true))
        }
      }
      
  return (
    <div className='container'>
        <div className='row'>
            <div className='col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 border rounded p-4 login-form'>
                <form action='' method='get' onSubmit={makeLogin}>
                    <h4 className='text-center mb-3'>{t('accedi_con_badge')}:</h4>
                    <div className='input-group mb-2'>
                        <input type='text' className='form-control rounded-left' ref={inputRef} value={badge} onChange={(e)=>{setBadge(e.target.value)}}/>
                        <button type='submit' className='basf-btn'>{t('accedi')}</button>
                    </div>
                    {user.wrong_login && <div className="form-text text-danger">{t('badge_non_trovato')}</div>}
                    <div className="form-text">{t('aiuto_accesso')}</div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Loginform