import { useTranslation } from "react-i18next";
import { ReactComponent as Flagita } from '../flag_ita.svg'
import { ReactComponent as Flageng } from '../flag_eng.svg'
import { ReactComponent as Flagdeu } from '../flag_deu.svg'

const Menu = () => {
  const {i18n} = useTranslation()

  const onChangeLang = (data) => {
    i18n.changeLanguage(data.code);
  };

  return (
    <nav>
      <div>

        <button className="invisible-btn">
            <Flagita id="it" className={i18n.language==='it'?" menu-flag flag-active me-2 me-md-3":"menu-flag me-2 me-md-3"} onClick={()=>{onChangeLang({code: 'it'})}} title="Italiano" />
        </button>
        <button className="invisible-btn">
            <Flageng className={i18n.language==='en'?" menu-flag flag-active me-2 me-md-3":"menu-flag me-2 me-md-3"} onClick={()=>{onChangeLang({code: 'en'})}} title="English"/>
        </button>
        <button className="invisible-btn">
            <Flagdeu className={i18n.language==='de'?"menu-flag flag-active me-0":"menu-flag me-0"} onClick={()=>{onChangeLang({code: 'de'})}} title="Deutsh"/>
        </button>
        
      </div>
    </nav>
  );
};

export default Menu;