import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { setVideoWatched } from '../app/store'

const Video = () => {

  const {i18n,t} = useTranslation();
  const user = useSelector((state)=> state.user)
  const dispatch = useDispatch()

  const [videoSrc,setVideoSrc] = useState("/video/"+process.env.REACT_APP_VIDEO_DRIVER_IT)
  const [playButtonClass,setPlayButtonClass] = useState('play-button')
  const [nextButtonState,setNextButtonState] = useState(true)
  const videoRef= useRef()

  const handleLanguageChanged = useCallback(() => {
    if(user.access_type==='1' || user.access_type===1){
    if(i18n.language==='it'){
      setVideoSrc(process.env.REACT_APP_LOCAL_SUBFOLDER+"/video/"+process.env.REACT_APP_VIDEO_DRIVER_IT)
    }
    if(i18n.language==='en'){
      setVideoSrc(process.env.REACT_APP_LOCAL_SUBFOLDER+"/video/"+process.env.REACT_APP_VIDEO_DRIVER_EN)
    }
    if(i18n.language==='de'){
      setVideoSrc(process.env.REACT_APP_LOCAL_SUBFOLDER+"/video/"+process.env.REACT_APP_VIDEO_DRIVER_DE)
    }
  }
  if(user.access_type==='2' || user.access_type===2){
    if(i18n.language==='it'){
      setVideoSrc(process.env.REACT_APP_LOCAL_SUBFOLDER+"/video/"+process.env.REACT_APP_VIDEO_CONTRACTOR_IT)
    }
    if(i18n.language==='en'){
      setVideoSrc(process.env.REACT_APP_LOCAL_SUBFOLDER+"/video/"+process.env.REACT_APP_VIDEO_CONTRACTOR_EN)
    }
    if(i18n.language==='de'){
      setVideoSrc(process.env.REACT_APP_LOCAL_SUBFOLDER+"/video/"+process.env.REACT_APP_VIDEO_CONTRACTOR_DE)
    }
  }
  if(user.access_type==='3' || user.access_type===3){
    if(i18n.language==='it'){
      setVideoSrc(process.env.REACT_APP_LOCAL_SUBFOLDER+"/video/"+process.env.REACT_APP_VIDEO_VISITOR_IT)
    }
    if(i18n.language==='en'){
      setVideoSrc(process.env.REACT_APP_LOCAL_SUBFOLDER+"/video/"+process.env.REACT_APP_VIDEO_VISITOR_EN)
    }
    if(i18n.language==='de'){
      setVideoSrc(process.env.REACT_APP_LOCAL_SUBFOLDER+"/video/"+process.env.REACT_APP_VIDEO_VISITOR_DE)
    }
  }
  showButton()
  },[i18n.language, user.access_type])

  useEffect(() => {
      handleLanguageChanged()
    }, [handleLanguageChanged])
  
  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
        i18n.off('languageChanged', handleLanguageChanged);
    };
}, [handleLanguageChanged,i18n])

useEffect(() => {    
  videoRef.current?.load()
}, [videoSrc])

const playVideo = () => {
  videoRef.current?.play()
}
const pauseVideo = () => {
  videoRef.current?.pause()
}
const hideButton = () => {
  setPlayButtonClass('d-none')
}
const showButton = () => {
  setPlayButtonClass('play-button')
}
const enableNextButton = () => {
  setNextButtonState(false)
}

const rewind = (seconds) => {
  videoRef.current.currentTime-=seconds
}
const forward = (seconds) => {
  videoRef.current.currentTime+=seconds
}

const startQuestions = () => {
  dispatch(setVideoWatched(true))
}

  return (
    <div className='w-100'>
    <div className="container px-3 py-3 border rounded-2 shadow">
      <div className='w-100'>
        <h3 className='text-center'>{t('benvenuto')}!</h3>
        <h4 className='text-center'>{t('istruzioni_video')}.</h4>
      </div>
      <div className="row">
        <div className='col-12 col-md-8 offset-md-2 video-player'>
          <video className='w-100' key={752893407543958} ref={videoRef} onClick={pauseVideo} onPlay={hideButton} onPause={showButton} onEnded={enableNextButton}>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <img className={playButtonClass} id="play_button" src={process.env.REACT_APP_LOCAL_SUBFOLDER+"/img/videoplay.png"} alt="PLAY" onClick={playVideo}/>
          
          <div className='w100 text-end'>
            <button className='invisible-btn me-2' onClick={()=>{rewind(30)}}><span className="material-symbols-rounded shift-down">fast_rewind</span><span className='text-little'>{t('indietro_30_secondi')}</span></button>
            {process.env.REACT_APP_ENABLE_VIDEO_FORWARD && <button className='invisible-btn' onClick={()=>{forward(30)}}><span className="material-symbols-rounded shift-down">fast_forward</span><span className='text-little'>{t('avanti_30_secondi')}</span></button>}
          </div>

        </div>
      </div>
    </div>
    <div className='container mt-4'>
      <div className='row'>
        <div className='col-12 d-flex justify-content-center align-items-center mb-2'>
          <button className='basf-btn' disabled={nextButtonState} onClick={()=>{startQuestions()}}>{t('inizia_il_test')}</button>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Video