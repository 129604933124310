import React from 'react'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import Loginform from './Loginform'
import { logOut, setVideoWatched, } from '../app/store'
import ResultsRecap from './ResultsRecap'
import Video from './Video'
import Quiz from './Quiz'
import ResultsRecapFail from './ResultsRecapFail'

const Centralbody = () => {

  const { t } = useTranslation();
  const user = useSelector((state)=> state.user)
  const questions = useSelector((state)=> state.questions)
  const dispatch = useDispatch()
  const handleLogout = () => {
    dispatch(logOut())
    dispatch(setVideoWatched(false))
  }

  return (
    <>
      {
      user.logged_in && <div className='corner-right d-flex justify-content-end align-items-center me-3'>
        <span className='ms-1'>{t('badge_numero') + ' ' + (user.logged_in?(user.badge_number?user.badge_number:'-'):'')}</span>
        <button className='basf-btn small-btn' onClick={()=>{handleLogout()}}>{t('esci')}</button>
      </div>
      }

      <div className='hero d-flex justify-content-center align-items-center'>
        
        {!user.logged_in && <Loginform />}
        {user.logged_in && user.exam_passed && <ResultsRecap/>}
        {user.logged_in && !questions.video_watched && !user.exam_passed && <Video />}
        {user.logged_in && questions.video_watched && !user.exam_passed && !user.exam_finished && <Quiz />}
        {user.exam_finished && !user.exam_passed && <ResultsRecapFail />}
      </div>
    </>
  )
}

export default Centralbody